import React, { useEffect, useRef } from "react";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperclip} from "@fortawesome/free-solid-svg-icons";
import AnexoIcone from "./Anexos/AnexoIcone";

export default function Mensagens({ mensagens }) {
  const mensagemListRef = useRef(null);

  useEffect(()=>{
    let element = mensagemListRef?.current;
    if(element){
      element.scrollTop = element.scrollHeight;
    }
  },[mensagens])

  function openImage(url) {
    window.open(url, "_blank");
  }

  function itemAnexo(arquivo){
    return(
      <div className="anexo-section" onClick={()=>{window.open(arquivo.url, '_blank')}}>
        <AnexoIcone anexo={arquivo?.nome?.split('.').pop()} style={{ fontSize: "1rem" }} />
        <span>{arquivo?.nome}</span>
      </div>
    )
  }

  function renderMensagem(mensagem) {
    const date = moment(mensagem.criado_em).format("DD/MM/YYYY, HH:mm");
    const { tipo, nome, text, url, tipo_upload, arquivos } = mensagem;
    const className = tipo === 'A' ? "Messages-message currentMember" : "Messages-message";
      
    return (
      <li className={className}>
        <div className="Message-content">
          <div className="username">{date + " " + nome}</div>
          <div className="text">{
          url 
          ? tipo_upload == 'I' 
          ? <img onClick={() => openImage(url)} style={{maxWidth: "100%", cursor: "pointer"}} src={url} alt={text} /> 
          : <div onClick={() => openImage(url)} style={{ cursor: "pointer"}}>
               <FontAwesomeIcon icon={faPaperclip} color="white" />  {text}
            </div>
              : text}
              {arquivos.length > 0 && 
                <>
                  <hr/>
                  {arquivos.map((arquivo, index)=>{
                    return (
                      <div key={index}>
                        {itemAnexo(arquivo)}
                      </div>
                    )
                  })}
                </>
              }
          </div>
        </div>
      </li>
    );
  }

  return (
    <ul ref={mensagemListRef} className="Messages-list">{mensagens.map((m) => renderMensagem(m))}</ul>
  );
}
