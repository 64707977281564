import React from 'react';
import Swal from "sweetalert2";

interface DommusAlertProps {
    title: string;
    message: string;
    type: 'success' | 'error' | 'warning' | 'info' | 'question';
    confirmButtonText?: string;
}

export function DommusAlert({ title, message, type, confirmButtonText='ok' }: DommusAlertProps) {
  return Swal.fire({
          title: title,
          text: message,
          icon: type,
          confirmButtonText: confirmButtonText
      })

}
