import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, Media } from "react-bootstrap";
import { Icon } from "@iconify/react";
import pageDoc from "@iconify-icons/foundation/page-doc";
import filePdf from '@iconify-icons/codicon/file-pdf';
import urlHelper from "../../../../../helpers/UrlHelper";
export default function Documentacao({ documentacao }) {
  return (
    <>
      <Card.Body>
        {Array.isArray(documentacao) && documentacao.map((documento, index) => (
          <>
            <div className="arquivo-proponente" key={index}>
                <div style={{minWidth: "1.7rem"}}>
                  <Icon
                    icon={filePdf}
                    // color="var(--dommus-color)" 
                    width={"1.7rem"}
                    height={"1.7rem"}
                  />
                </div>
                <span
                  onClick={() => {
                    if (documento.caminho.match(/https/gi)) {
                      urlHelper.download(documento.caminho);
                    } else {
                      var newstr = documento.caminho.replace(/http/gi, "https");
                      urlHelper.download(newstr);
                    }
                  }}
                >
                  <Card.Text>{documento.descricao_documento}</Card.Text>
                </span>
            </div>
            <hr/>
          </>
        ))}
      </Card.Body>
    </>
  );
}
